import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/models/app.state';
import { PharDragService } from '../../../drag.service';
import { setDragged, setDraggedHelper } from '../../../../store/actions/ui.actions';
import { FormElementsEnum } from '../../../../form/form-elements.enum';
import { PharTooltipDirective } from '../../../directives/tooltip.directive';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'phar-question-group-widget',
  templateUrl: 'question-group-widget.component.html',
  styleUrl: 'question-group-widget.component.scss',
  standalone: true,
  imports: [PharTooltipDirective, NgOptimizedImage],
})
export class QuestionGroupWidgetComponent {
  public store = inject(Store<AppState>);
  public dragService = inject(PharDragService);

  type = FormElementsEnum.QuestionGroup;

  get dragData(): any {
    return {
      control: {
        controlID: '',
        label: '',
        info: '',
        controlType: this.type,
        validators: {},
        bindDataField: '',
        dependencies: {},
        controls: [],
      },
    };
  }

  onDragStart(event): void {
    event.stopImmediatePropagation();
    this.dragService.setDragData(this.dragData);
    this.store.dispatch(setDragged({ dragged: true }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: { type: this.type, reorder: false } }));
  }

  onDragEnd(): void {
    this.store.dispatch(setDragged({ dragged: false }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
  }
}

import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Component, inject, Injector, OnDestroy, OnInit, Signal } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { FormModel, FormTypeEnum } from '../form.model';
import { CurrentFormManage, selectCurrentFormState } from '../store/form.state';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '../../store/models/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';

import {
  createForm,
  dispatchedCreateUpdateForm,
  pendingCreateUpdateForm,
  populateCurrentFormSnapshot,
  refreshCurrentForm,
  successCreateUpdateForm,
  updateForm,
  updateFormField,
} from '../store/form.actions';
import { globalLoading, updateContextTitle } from '../../store/actions/ui.actions';
import { UtilsService } from '../../core/utils.service';
import { PendingChangesControl } from '../../shared/guards/pending-changes.guard';
import { cloneDeep, isEqual } from 'lodash-es';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { FormService } from '../form.service';
import { updateQuestions } from '../../question/store/question.actions';
import { QuestionModel } from '../../question/question.model';
import { ILanguage } from '../../shared/models/language.interface';
import { loadSettings, selectLanguages } from '../../store/actions/settings.actions';
import { BaseComponent } from '../../shared/base.class';
import { loadProjectList, selectProjectList } from '../../project/store/project.actions';
import { ProjectModel } from '../../project/project.model';
import { VERSION_PATTERN } from '../../core/config/app.constants';
import { AppConfig } from '../../core/config/app.config';
import { Statuses } from '../../shared/models/statuses.enum';
import { ValidationsService } from '../../core/helpers/validations.service';
import { VersionInputDialogComponent } from '../../shared/version-input-dialog/version-input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { toSignal } from '@angular/core/rxjs-interop';

type EditableFormDetails = Pick<
  FormModel,
  'name' | 'description' | 'type' | 'version' | 'languageId' | 'isMandatoryToSign'
>;

@Component({
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss'],
})
export class FormDetailsComponent extends BaseComponent implements OnInit, OnDestroy, PendingChangesControl {
  form: UntypedFormGroup;
  formId: number;
  formTypes: { name: string; type: FormTypeEnum }[];
  inProjectContext = false;
  isNewForm = false;
  formState: Signal<CurrentFormManage>;
  formState$: Observable<CurrentFormManage>;
  currentForm$: Observable<FormModel>;
  currentFormSnapshot$: Observable<FormModel>;
  originalForm$: Observable<FormModel | null>;
  amendForm$: Observable<FormModel | null>;
  projects$: Observable<ProjectModel[]>;
  languages$: Observable<ILanguage[]>;
  hasPendingChanges$: Observable<boolean>;
  globalLoading$: Observable<boolean>;
  readonly formStatusEnum = Statuses;
  readonly DEFAULT_VERSION: string = '0.1';
  readonly MIN_NEW_FORM_VERSION: string = '0';
  appConfig = inject(AppConfig);
  injector = inject(Injector);

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly utilsService: UtilsService,
    private readonly confirmDialog: PharConfirmDialogService,
    private readonly formService: FormService,
    private readonly validationsService: ValidationsService,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  get currentForm(): FormModel {
    return this.formState().form;
  }

  ngOnInit(): void {
    this.globalLoading$ = this.store.select(state => state.ui.globalLoading);
    if (Object.prototype.hasOwnProperty.call(this.route.snapshot.data as { isContext?: boolean }, 'isContext')) {
      this.inProjectContext = this.route.snapshot.data.isContext;
    }
    if (Object.prototype.hasOwnProperty.call(this.route.snapshot.data as { isNewForm?: boolean }, 'isNewForm')) {
      this.isNewForm = this.route.snapshot.data.isNewForm;
    }

    this.formTypes = [
      {
        name: 'eClinRO',
        type: FormTypeEnum.Questionnaire,
      },
      {
        name: 'ePRO',
        type: FormTypeEnum.DataCapture,
      },
      // {
      //   name: 'eObsRO',
      //   type: FormTypeEnum.EObsRO,
      // },
      {
        name: 'Informed Consent',
        type: FormTypeEnum.InformedConsent,
      },
    ];
    this.formState$ = this.store.select(state => state.form.current);
    this.currentForm$ = this.formState$.pipe(
      map(current => current.form),
      distinctUntilChanged(isEqual),
    );
    this.formState = toSignal(this.store.select(selectCurrentFormState), { injector: this.injector });
    this.currentFormSnapshot$ = this.formState$.pipe(
      map(current => current.formSnapshot),
      distinctUntilChanged(isEqual),
    );
    this.hasPendingChanges$ = combineLatest([this.currentForm$, this.currentFormSnapshot$]).pipe(
      map(forms => {
        return forms.map((form: FormModel): EditableFormDetails => {
          const { name, type, version, description, languageId, isMandatoryToSign } = form;
          return { name, type, version, description, languageId, isMandatoryToSign };
        });
      }),
      map(([editableFormDetails, editableFormDetailsSnapshot]) => {
        return !isEqual(editableFormDetails, editableFormDetailsSnapshot);
      }),
      distinctUntilChanged(),
    );
    this.originalForm$ = this.currentForm$.pipe(
      map(form => form.formDuplicateId),
      distinctUntilChanged(),
      switchMap(formDuplicateId => {
        if (!formDuplicateId) {
          return of(null);
        }

        return this.formService.searchById(formDuplicateId);
      }),
    );
    this.amendForm$ = this.currentForm$.pipe(
      map(form => form.formAmendId),
      distinctUntilChanged(),
      switchMap(formAmendId => {
        if (!formAmendId) {
          return of(null);
        }

        return this.formService.searchById(formAmendId);
      }),
    );
    this.languages$ = this.store
      .select(selectLanguages)
      .pipe(map(languages => languages.filter(lang => this.appConfig.config.allowedLanguageCodes.includes(lang.code))));
    this.projects$ = this.store.select(selectProjectList);

    this.createInitialForm();
    this.synchronizeWithStore();
    this.store.dispatch(loadSettings());
    this.store.dispatch(loadProjectList());
    this.store.dispatch(successCreateUpdateForm({ success: false }));
  }

  ngOnDestroy(): void {
    if (this.formState().pendingChanges && !this.isNewForm) {
      this.store.dispatch(refreshCurrentForm());
    }
    super.ngOnDestroy();
  }

  saveAndCheckPendingChanges(): Observable<boolean> {
    //this method is used in PendingChangesGuard to save pending changes
    //and to check if component can be deactivated
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return of(true);
    }
    this.store.dispatch(globalLoading(true));

    if (this.isNewForm) {
      const currentForm = this.currentForm;
      const isInformedConsent = currentForm.type === FormTypeEnum.InformedConsent;

      const form: FormModel = {
        ...currentForm,
        body: {
          ...currentForm.body,
          pages: [
            {
              title: 'Page 1',
              pageID: 'P-1',
              questions: [],
              buttons: [],
              page_uuid: this.utilsService.generateUUID(),
            },
          ],
        },
        formStatus: Statuses.Draft,
        isLocked: false,
        ...(isInformedConsent && {
          settings: { ...currentForm.settings, showHeader: true, showFooter: true },
        }),
      };

      this.utilsService.dispatchActions(this.store, [
        pendingCreateUpdateForm({ pending: true }),
        dispatchedCreateUpdateForm({ dispatched: true }),
        createForm({ form }),
      ]);
    } else {
      const { form, formSnapshot } = cloneDeep(this.formState());
      const questionsToUpdated: QuestionModel[] = [];
      if (formSnapshot.type === FormTypeEnum.Questionnaire && form.type !== FormTypeEnum.Questionnaire) {
        Object.keys(form.questionsPopulated).forEach(questionId => {
          if (form.questionsPopulated[questionId].iterationOnNewPage) {
            form.questionsPopulated[questionId].iterationOnNewPage = false;
            questionsToUpdated.push(form.questionsPopulated[questionId]);
          }
        });
      }
      this.form.value.id = this.currentForm.id;
      const actions: Action[] = [
        pendingCreateUpdateForm({ pending: true }),
        dispatchedCreateUpdateForm({ dispatched: true }),
        updateForm({ form }),
      ];
      if (questionsToUpdated.length) {
        actions.unshift(updateQuestions({ questions: questionsToUpdated }));
      }

      this.utilsService.dispatchActions(this.store, actions);
    }

    return this.formState$.pipe(
      filter(({ dispatched, success }) => dispatched && success),
      tap(currentForm => {
        this.store.dispatch(
          updateContextTitle({
            title: currentForm.form.name,
            position: this.inProjectContext || this.isNewForm ? 2 : 1,
          }),
        );
        this.utilsService.dispatchActions(this.store, [
          pendingCreateUpdateForm({ pending: false }),
          successCreateUpdateForm({ success: false }),
        ]);
      }),
      switchMap(() => this.hasPendingChanges$),
      take(1),
    );
  }

  addEditForm(): void {
    this.saveAndCheckPendingChanges()
      .pipe(
        filter(hasPendingChanges => !hasPendingChanges && this.isNewForm),
        // switchMap(() => this.confirmDialog.openConfirmDialog( // This should stay commented for now
        //   'Navigate to the Questions tab to begin adding items to your form.',
        //   '',
        //   'Confirm',
        //   '',
        //   false,
        //   300,
        //   true
        // )),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // navigate when new form is created
        this.router.navigate(['../../edit/' + this.currentForm.id], { relativeTo: this.route });
      });
  }

  returnToDraftAndUnlockForm(form: FormModel): void {
    const proposedNewVersion = this.utilsService.incrementVersion(form.version, 'minor');

    this.confirmDialog
      .openConfirmDialog(
        `Do you want to update the version number to ${proposedNewVersion}?`,
        `${form.name} V${form.version}`,
        'Yes',
        'No',
      )
      .pipe(
        switchMap(isConfirmed => {
          if (isConfirmed) {
            return of(proposedNewVersion);
          }

          return this.dialog
            .open(VersionInputDialogComponent, {
              width: '300px',
              data: {
                title: `${form.name} V${form.version}`,
                defaultVersion: form.version || this.DEFAULT_VERSION,
              },
            })
            .afterClosed();
        }),
        take(1),
      )
      .subscribe(version => {
        if (version) {
          this.store.dispatch(updateFormField({ field: 'version', value: version }));
          this.form.controls.version.setValue(version);
        }

        this.store.dispatch(updateFormField({ field: 'formStatus', value: Statuses.Draft }));
        this.store.dispatch(updateFormField({ field: 'isLocked', value: false }));
        this.addEditForm();
      });
  }

  originalFormClick(originalForm: FormModel): void {
    const module = originalForm.formStatus === Statuses.Released ? 'library' : 'workspace';

    this.router.navigate([`../../../${module}`, 'edit', originalForm.id], { relativeTo: this.route.parent });
  }

  /**
   * create the form
   */

  createInitialForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(500)]],
      description: ['', Validators.maxLength(500)],
      type: [null, Validators.required],
      version: [
        this.DEFAULT_VERSION,
        [
          Validators.required,
          Validators.pattern(VERSION_PATTERN),
          this.validationsService.minVersionValidator(this.MIN_NEW_FORM_VERSION),
        ],
      ],
      languageId: [null, Validators.required],
      isMandatoryToSign: [{ value: false, disabled: true }],
    });

    if (this.isNewForm) {
      const formBody = this.currentForm.body;
      const formPages = formBody.pages.map(page => ({ ...page, page_uuid: this.utilsService.generateUUID() }));
      this.store.dispatch(updateFormField({ field: 'body', value: { ...formBody, pages: formPages } }));
      this.store.dispatch(updateFormField({ field: 'version', value: this.DEFAULT_VERSION }));
      this.store.dispatch(populateCurrentFormSnapshot({ form: this.currentForm }));
    }

    // this.permissionService.hasPermission('FormEdit').then(value => {
    //   if (!value) {
    //     this.form.disable();
    //   }
    // });
  }

  /**
   * sync with store
   */
  synchronizeWithStore(): void {
    this.currentFormSnapshot$
      .pipe(
        map(() => this.currentForm),
        filter(() => !this.isNewForm),
        takeUntil(this.destroy$),
      )
      .subscribe((form: FormModel) => {
        const updatedForm: FormModel = form;
        this.formId = form.id;
        this.form.patchValue(updatedForm, { emitEvent: false });
        this.form
          .get('version')
          .setValidators([
            Validators.required,
            Validators.pattern(VERSION_PATTERN),
            this.validationsService.minVersionValidator(updatedForm.version),
          ]);

        if (form.isLocked || form.isArchived) {
          this.form.disable();
        } else {
          this.form.enable();
          this.form.get('type')?.disable();

          if (form.type !== FormTypeEnum.InformedConsent) {
            this.form.get('isMandatoryToSign').disable();
          }
        }
      });

    this.form
      .get('name')
      .valueChanges.pipe(
        debounceTime(500),
        map(name => name?.trim()),
        takeUntil(this.destroy$),
      )
      .subscribe(title => {
        this.store.dispatch(updateFormField({ field: 'name', value: title }));
        if (this.inProjectContext || this.isNewForm) {
          this.store.dispatch(updateContextTitle({ title, position: 2 }));
        }
      });

    this.form
      .get('description')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(description => {
        this.store.dispatch(updateFormField({ field: 'description', value: description }));
      });

    this.form
      .get('type')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((type: FormTypeEnum) => {
        this.store.dispatch(updateFormField({ field: 'type', value: type }));

        if (type === FormTypeEnum.InformedConsent) {
          this.form.get('isMandatoryToSign').enable();
        } else {
          this.form.get('isMandatoryToSign').setValue(false);
          this.form.get('isMandatoryToSign').disable();
        }
      });

    this.form
      .get('version')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(version => {
        this.store.dispatch(updateFormField({ field: 'version', value: version }));
      });

    this.form
      .get('languageId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(languageId => {
        this.store.dispatch(updateFormField({ field: 'languageId', value: languageId }));
      });

    this.form
      .get('isMandatoryToSign')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(isMandatoryToSign => {
        this.store.dispatch(updateFormField({ field: 'isMandatoryToSign', value: isMandatoryToSign }));
      });
  }
}

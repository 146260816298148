import { Component, Injector, Input, OnInit, Signal } from '@angular/core';
import { RulesType } from './rules-type.enum';
import { AppState, getState, selectCurrentControl } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ControlModel } from '../control.model';
import { filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { isArray, isEmpty } from 'lodash-es';
import { selectCurrentQuestionPendingChanges } from '../store/question.state';
import { PendingChangesService } from '../../shared/pending-changes.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { EDIT_CHECK_DISABLE_LIST } from './edit-check-disable-list';
import { FormElementsEnum } from '../../form/form-elements.enum';
import { BaseComponent } from '../../shared/base.class';

@Component({
  selector: 'phar-rules-list',
  templateUrl: 'rules-list.component.html',
  styleUrl: './rules-list.component.scss',
})
export class RulesListComponent extends BaseComponent implements OnInit {
  @Input() builderMode = true;
  activatedTab: RulesType | null = null;
  rulesLength$: Observable<{ [RulesType.Conditions]: number; [RulesType.EditChecks]: number }>;
  hasPendingChanges$: Observable<boolean>;
  rulesDisabled: Signal<boolean>;
  protected readonly RulesType = RulesType;

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private pendingChangesService: PendingChangesService,
  ) {
    super();
  }

  ngOnInit() {
    this.rulesLength$ = this.store.select(selectCurrentControl).pipe(
      filter(({ controlID }) => !!controlID),
      map((ctrl: ControlModel) => {
        const rulesLength = {
          [RulesType.Conditions]: 0,
          [RulesType.EditChecks]: 0,
        };
        if (isEmpty(ctrl.dependencies)) {
          return rulesLength;
        }
        Object.keys(ctrl.dependencies).forEach((ruleType: string) => {
          Object.keys(ctrl.dependencies[ruleType]).forEach((ruleAction: string) => {
            if (ctrl.dependencies[ruleType][ruleAction].data) {
              rulesLength[ruleType] += ctrl.dependencies[ruleType][ruleAction].data.length;
            }

            if (isArray(ctrl.dependencies[ruleType])) {
              rulesLength[ruleType] = (ctrl.dependencies[ruleType] as []).length;
            }
          });
        });
        return rulesLength;
      }),
      takeUntil(this.destroy$),
      shareReplay(1),
    );

    this.hasPendingChanges$ = this.store.select(selectCurrentQuestionPendingChanges);

    this.rulesDisabled = toSignal(
      this.store.select(selectCurrentControl).pipe(
        map((control: ControlModel) => {
          return EDIT_CHECK_DISABLE_LIST.includes(control.controlType as FormElementsEnum);
        }),
      ),
      {
        injector: this.injector,
      },
    );

    this.rulesLength$.pipe(take(1)).subscribe(rulesLength => {
      if (rulesLength[RulesType.Conditions]) {
        this.activatedTab = RulesType.Conditions;
      } else if (!this.rulesDisabled() && rulesLength[RulesType.EditChecks]) {
        this.activatedTab = RulesType.EditChecks;
      }
    });
  }

  activateTab(tab: RulesType): void {
    if (this.activatedTab === tab) {
      this.activatedTab = null;
      return;
    }
    this.activatedTab = tab;
  }

  saveGroup(): void {
    const currentQuestion = getState(this.store).question.current.question;
    this.pendingChangesService.triggerSave(currentQuestion.id);
  }

  triggerDiscard(): void {
    this.pendingChangesService.triggerDiscardChanges();
  }
}

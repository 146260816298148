import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewFormComponent } from './new-form/new-form.component';
import { FormRoutingModule } from './form-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormService } from './form.service';
import { ListFormComponent } from './list-form/list-form.component';
import { QuestionModule } from '../question/question.module';
import { FormPageComponent } from './form-page/form-page.component';
import { FormEditInContextComponent } from './form-edit-in-context/form-edit-in-context.component';
import { FormDetailsComponent } from './form-details/form-details.component';
import { FormContentComponent } from './form-content/form-content.component';
import { FormContextComponent } from './form-context/form-context.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormApprovalsComponent } from './form-approvals/form-approvals.component';
import { PreviewFormQuestionsDialogComponent } from './preview-form-questions-dialog/preview-form-questions-dialog.component';
import { QuestionGroupEditorComponent, QuestionGroupWidgetComponent } from '../shared/phar-controls/question-group';
import { MobilePreviewFormQuestionsDialogComponent } from './mobile-preview-form-questions-dialog/mobile-preview-form-questions.component';
import { FormTypePipe } from './pipes/form-type.pipe';
import { UserByIdPipe } from './pipes/user-by-id.pipe';
import { ListTemplateComponent } from '../shared/list-template/list-template.component';
import { PharTooltipDirective } from '../shared/directives/tooltip.directive';
import { HelpIconComponent } from '../shared/help-icon/help-icon.component';
import { PharAutocompleterComponent } from '../shared/phar-dropdown-list-autocompleter/phar-autocompleter.component';
import { HeaderWidgetComponent } from '../shared/phar-controls/header/header-widget/header-widget.component';
import { FooterWidgetComponent } from '../shared/phar-controls/footer/footer-widget/footer-widget.component';
import { HeaderEditorComponent } from '../shared/phar-controls/header/header-editor/header-editor.component';
import { FooterEditorComponent } from '../shared/phar-controls/footer/footer-editor/footer-editor.component';
import { LanguagePipe } from '../project/sites-and-study-team/pipes/language.pipe';
import { NoPropagationDirective } from '../shared/directives/no-propagation.directive';
import { TableListViewComponent } from '../shared/table-list-view/table-list-view.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormRoutingModule,
    QuestionModule,
    NgxPermissionsModule.forChild(),
    QuestionGroupWidgetComponent,
    HeaderWidgetComponent,
    QuestionGroupEditorComponent,
    FormTypePipe,
    UserByIdPipe,
    ListTemplateComponent,
    PharTooltipDirective,
    HelpIconComponent,
    PharAutocompleterComponent,
    FooterWidgetComponent,
    HeaderEditorComponent,
    FooterEditorComponent,
    LanguagePipe,
    NoPropagationDirective,
    TableListViewComponent,
  ],
  providers: [FormService],
  declarations: [
    NewFormComponent,
    ListFormComponent,
    FormPageComponent,
    FormEditInContextComponent,
    FormDetailsComponent,
    // FormSettingsComponent,
    FormContentComponent,
    FormContextComponent,
    FormApprovalsComponent,
    PreviewFormQuestionsDialogComponent,
    MobilePreviewFormQuestionsDialogComponent,
  ],
})
export class PharFormModule {}
